/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
	// All pages
	'common': {
	  init: function() {

		/* =============================================================================
		   superfish
		   ========================================================================== */

		// initialise plugin
		var menu_primary_navigation = $('#menu-primary-navigation').superfish({
			//add options here if required
		});

		/* =============================================================================
		   nav-overlay
		   ========================================================================== */

		//open/close primary navigation
		$('.cd-primary-nav-trigger').on('click', function(){
			$('.cd-menu-icon').toggleClass('is-clicked'); 
			$('header').toggleClass('menu-is-open');
				
			//in firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
			if( $('.cd-primary-nav').hasClass('is-visible') ) {
				$('.cd-primary-nav').removeClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
					$('body').removeClass('overflow-hidden');
				});
			} else {
				$('.cd-primary-nav').addClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
					$('body').addClass('overflow-hidden');
				});	
			}
		});

		/* =============================================================================
		   matchHeight
		   ========================================================================== */
				
		$('.eqheight').matchHeight({
			property: 'height',
			byRow: false
		});

		$('.eqheight-row').matchHeight({
			property: 'height'
		});

		/* =============================================================================
		   Fancybox Media
		   ========================================================================== */

		$('.fancybox-media')
			.attr('rel', 'media-gallery')
			.fancybox({
				openEffect : 'none',
				closeEffect : 'none',
				prevEffect : 'none',
				nextEffect : 'none',

				arrows : false,
				helpers : {
					media : {},
					buttons : {}
				}
			});

		/* =============================================================================
		   Accordion - http://www.designchemical.com/lab/jquery-vertical-accordion-menu-plugin/getting-started/
		   ========================================================================== */

		$('#accordion').dcAccordion({
			menuClose: false,
			autoExpand: false,
			saveState: false,
			cookie: 'dcjq-accordion',
			classExpand: 'dcjq-current-parent'
		});

		$('#training_accordion').dcAccordion({
			menuClose: false,
			autoExpand: false,
			saveState: false,
			cookie: 'dcjq-accordion',
			classExpand: 'dcjq-current-parent'
		});

		$('#investment_accordion').dcAccordion({
			menuClose: false,
			autoExpand: false,
			saveState: false,
			cookie: 'dcjq-accordion',
			classExpand: 'dcjq-current-parent'
		});

		/* =============================================================================
		   RoyalSlider
		   ========================================================================== */

		$(".slider").royalSlider({
			imageScaleMode: 'fill',
			controlNavigation: 'none',
			arrowsNavHideOnTouch: false,
			/* size of all images http://help.dimsemenov.com/kb/royalslider-jquery-plugin-faq/adding-width-and-height-properties-to-images */
			imgWidth: 860,
			imgHeight: 400,

			loop: true,
			transitionSpeed: 1000,
			autoPlay: {
							// autoplay options go here
							enabled: true,
							pauseOnHover: false,
							delay: 3500
				}
		});

	  },
	  finalize: function() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	  }
	},
	// Home page
	'home': {
	  init: function() {
		// JavaScript to be fired on the home page
	  },
	  finalize: function() {
		// JavaScript to be fired on the home page, after the init JS
	  }
	},
	// About us page, note the change from about-us to about_us.
	'about_us': {
	  init: function() {
		// JavaScript to be fired on the about us page
	  }
	}
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
	fire: function(func, funcname, args) {
	  var fire;
	  var namespace = Sage;
	  funcname = (funcname === undefined) ? 'init' : funcname;
	  fire = func !== '';
	  fire = fire && namespace[func];
	  fire = fire && typeof namespace[func][funcname] === 'function';

	  if (fire) {
		namespace[func][funcname](args);
	  }
	},
	loadEvents: function() {
	  // Fire common init JS
	  UTIL.fire('common');

	  // Fire page-specific init JS, and then finalize JS
	  $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
		UTIL.fire(classnm);
		UTIL.fire(classnm, 'finalize');
	  });

	  // Fire common finalize JS
	  UTIL.fire('common', 'finalize');
	}
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
